import '../scss/style.scss';

document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('.acc-head').forEach(item => {
        item.addEventListener('click', event => {
            if (item.classList.contains('active')) {
                item.nextElementSibling.classList.remove('show');
                item.classList.remove('active');
            } else {
                document.querySelectorAll('.acc-content').forEach(content => {
                content.classList.remove('show');
                });
                document.querySelectorAll('.acc-head').forEach(head => {
                head.classList.remove('active');
                });
                item.nextElementSibling.classList.add('show');
                item.classList.add('active');
            }
        });
    });

    const boxLines = document.querySelectorAll('.box-line');
    const contentLines = document.querySelectorAll('.content-line');

    boxLines.forEach((box, index) => {
        box.addEventListener('click', () => {
            toggleTab(index);
        });
    });

    function toggleTab(index) {
        for (let i = 0; i < boxLines.length; i++) {
            if (i === index) {
                boxLines[i].classList.add('active');
                contentLines[i].classList.add('active');
            } else {
                boxLines[i].classList.remove('active');
                contentLines[i].classList.remove('active');
            }
        }
    }

    const moreBtns = document.querySelectorAll('.more-btn');
    const textCols = document.querySelectorAll('.text-col');

    moreBtns.forEach((btn, index) => {
        btn.addEventListener('click', () => {
            toggleTextCol(index);
        });
    });

    function toggleTextCol(index) {
        textCols.forEach((col, i) => {
            if (i === index) {
                col.classList.remove('hidden');
                col.classList.add('block');
            } else {
                col.classList.remove('block');
                col.classList.add('hidden');
            }
        });
    }

    const boxes = document.querySelectorAll(".box-open");
    const modals = document.querySelectorAll(".modal");
    const closeButtons = document.querySelectorAll(".close-modal");
    const nextButtons = document.querySelectorAll(".next-modal");

    let currentModalIndex = -1;

    function showElement(element) {
        element.classList.remove("hidden");
        element.classList.add("block");
    }

    function hideElement(element) {
        element.classList.remove("block");
        element.classList.add("hidden");
    }

    boxes.forEach(box => {
        box.addEventListener("click", function () {
            const modalId = box.getAttribute("data-modal");

            modals.forEach(modal => {
                hideElement(modal);
            });

            const modal = document.getElementById(modalId);
            showElement(modal);
            currentModalIndex = Array.from(modals).indexOf(modal);
            startCountdown(modal);
        });
    });

    function goToNextModal() {
        if (currentModalIndex !== -1) {
            hideElement(modals[currentModalIndex]);
        }
        currentModalIndex = (currentModalIndex + 1) % modals.length;
        showElement(modals[currentModalIndex]);
        startCountdown(modals[currentModalIndex]);
    }

    nextButtons.forEach(button => {
        button.addEventListener("click", goToNextModal);
    });

    function startCountdown(modal) {
    }

    closeButtons.forEach(button => {
        button.addEventListener("click", function (event) {
        event.preventDefault();
        const modal = button.closest(".modal");
            modal.classList.remove("block");
            modal.classList.add("hidden");
        });
    });

    const openNavigation = document.querySelector('.open-navigation');
    const navShow = document.querySelector('.nav-show');
    const navMain = document.querySelector('.nav-main');
    const closeNavigation = document.querySelector('.close-navigation');

    openNavigation.addEventListener('click', function (e) {
        e.preventDefault();
        navShow.classList.remove('hidden');
        navShow.classList.add('block');
        setTimeout(function () {
            navMain.classList.add('move-nav');
        }, 300);
    });

    const liElements = document.querySelectorAll('.sub');
    const allLiElements = document.querySelectorAll('.navigation-main ul li');

    const onlyHoverElement = document.querySelector('.only-hover');
    liElements.forEach(li => {
        li.addEventListener('mouseover', () => {
            if (window.innerWidth > 680) {
                onlyHoverElement.style.opacity = 1;
            }
        });

        li.addEventListener('mouseout', () => {
            onlyHoverElement.style.opacity = 0;
        });
    });

    function closeAllSubMenus() {
        liElements.forEach(li => {
            li.classList.remove('open');
            const ulElement = li.querySelector('ul');
            ulElement.classList.remove('block');
            ulElement.classList.add('sm:hidden');
        });
    }

    allLiElements.forEach(li => {
        li.addEventListener('click', () => {
            closeAllSubMenus(); // Cerrar todos los menús desplegables
        });
    });

    liElements.forEach(li => {
        li.addEventListener('click', () => {
            if (window.innerWidth < 680) {
                li.classList.toggle('open');
                const ulElement = li.querySelector('ul');

                const allOtherSubs = document.querySelectorAll('.sub:not(.open)');
                allOtherSubs.forEach(sub => {
                    sub.classList.remove('open');
                });

                const allUlElements = document.querySelectorAll('.sub ul');
                allUlElements.forEach(ul => {
                    ul.classList.remove('block');
                    ul.classList.add('sm:hidden');
                });

                ulElement.classList.toggle('block');
                ulElement.classList.remove('sm:hidden');
            }
        });

        const firstLink = li.querySelector('a');
        firstLink.addEventListener('click', (event) => {
            if (window.innerWidth < 680) {
                event.preventDefault();
                const ulElement = li.querySelector('ul');
                ulElement.classList.toggle('block');
                ulElement.classList.remove('sm:hidden');

                const allOtherSubs = document.querySelectorAll('.sub:not(.open)');
                allOtherSubs.forEach(sub => {
                    sub.classList.remove('open');
                });

                const allUlElements = document.querySelectorAll('.sub ul');
                allUlElements.forEach(ul => {
                    if (ul !== ulElement) {
                        ul.classList.remove('block');
                        ul.classList.add('sm:hidden');
                    }
                });
            }
        });
    });

    closeNavigation.addEventListener('click', function (e) {
        e.preventDefault();
        closeAllSubMenus();
        navMain.classList.remove('move-nav');
        setTimeout(function () {
            navShow.classList.remove('block');
            navShow.classList.add('hidden');
        }, 300);
    });

    if(document.querySelector('.cloud2')) {
        const cloud2 = document.querySelector('.cloud2');

        window.addEventListener('scroll', function() {
            const scrollValue = window.scrollY;

            const translateY = -scrollValue * 0.5;
            cloud2.style.backgroundPositionY = translateY + 'px';
        });
    }

});